var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("account")) + " AWARIS")]), _c('div', {
    staticClass: "form-group",
    class: {
      'has-error': _vm.$v.form.ic_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "ic_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm.wasiat ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.wasiat.user.ic_number) + " ")]) : _vm._e(), !_vm.wasiat ? _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "readonly": _vm.wasiat,
      "mask": ['######-##-####']
    },
    model: {
      value: _vm.$v.form.ic_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.ic_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.form.ic_number.$model"
    }
  }) : _vm._e(), !_vm.$v.form.ic_number.minLength ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad-should")) + " " + _vm._s(_vm.$v.form.ic_number.$params.minLength.min) + " " + _vm._s(_vm.$t("validation.characters")) + " ")]) : _vm._e(), !_vm.$v.form.ic_number.maxLength ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad-should")) + " " + _vm._s(_vm.$v.form.ic_number.$params.maxLength.max) + " " + _vm._s(_vm.$t("validation.characters")) + " ")]) : _vm._e(), _vm.$v.form.ic_number.$error && !_vm.$v.form.ic_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "hibah_type_id"
    }
  }, [_vm._v(_vm._s(_vm.$t("product")) + " ")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.$t("wasiat")))])])])]), [0].includes(_vm.wasiat ? _vm.wasiat.status : 0) ? _c('div', {
    staticClass: "text-right mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("save-next")) + " ")])]) : _vm._e(), _vm.wasiat && [1, 2].includes(_vm.wasiat.status) ? _c('div', {
    staticClass: "text-right mt-3"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.nextPart
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }